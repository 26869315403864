section.canva-404 {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .item {
    position: absolute;
    color: $white;
    font-size: 0.7rem;
    font-weight: 500;
    user-select: none;
    opacity: 0;
    scale: 0.5;
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
  }
}
