.cursor {
  width: 40px;
  height: 40px;
  position: fixed;
  backdrop-filter: invert(1);
  border-radius: 50%;
  pointer-events: none;
  z-index: 10000000;
}

@media screen and (max-width: 768px) {
  .cursor {
    display: none;
  }
  * {
    cursor: auto;
  }
}
