.gallery {
  .gallery__filters {
    .col-1 {
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-of-type {
        justify-content: flex-start;
      }
      &:last-of-type {
        justify-content: flex-end;
      }
    }
  }

  .col-12,
  .col-md-4 {
    padding-top: 1.8rem;
  }

  .gallery__item {
    padding-bottom: 1.8rem;
    pointer-events: auto;
    transition: transform 0.3s cubic-bezier(1, 0, 0, 1);
    &:hover {
      transform: scale(1.1);
    }
  }
}

@media screen and (max-width: 768px) {
  .gallery {
    .gallery__item {
      padding-bottom: 1.2rem;
    }
    .col-12,
    .col-md-4 {
      padding-top: 0;
      &:first-of-type {
        padding-top: 1.2rem;
      }
    }
  }
}

.gallery__item--active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 1.8rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(20px);
  z-index: 1000;
  img,
  video {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    order: 2;
    border-radius: 10px;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
  }
  p {
    color: $white;
    font-size: 1.5rem;
    font-weight: 500;
    order: 3;
  }
  button {
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    background-color: transparent;
    border: none;
    align-self: flex-end;
    order: 1;
  }
}
