/* ====================================
// css variables
// ==================================== */

$black: #111;
$white: #f6f6f6;
$enhanced: #81ff5a;

/* ====================================
// reset rules
// ==================================== */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  &::selection {
    background-color: $enhanced;
    color: $white;
  }
  cursor: none;
}

html {
  font-size: 16px;
  color: $white;
  background-color: $black;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500 !important;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  font-size: 1rem;
  line-height: 1.25;
}

a {
  color: inherit;
  text-decoration: none;
  position: relative;
  transition: color 0.3s cubic-bezier(1, 0, 0, 1);
  &:hover {
    color: $enhanced;
  }
}

.button {
  position: relative;
  height: auto;
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    backdrop-filter: invert(1);
    position: absolute;
    bottom: 0;
    transition: all 0.3s cubic-bezier(1, 0, 0, 1);
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
}

img,
video {
  max-inline-size: 100%;
  block-size: auto;
  vertical-align: middle;
}

/* ====================================
// base rules
// ==================================== */

header {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: $black;
  padding: 1.8rem 0;
  nav {
    .col-4 {
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-of-type {
        justify-content: flex-start;
      }
      &:last-of-type {
        justify-content: flex-end;
      }
    }
  }
}

.homelander {
  a {
    &:hover {
      color: inherit;
    }
  }
  .homeland {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    video {
      position: absolute;
      scale: 0.8;
      z-index: -1;
      filter: invert(1);
      mix-blend-mode: lighten;
    }
    h1 {
      width: calc(100% / 12 * 9);
      font-size: 20vw;
      font-weight: 500;
    }
  }
}

footer {
  margin-top: 100px;
  padding: 3rem 1.8rem;
  color: $white;
  background-color: $black;
  position: relative;
  h3 {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .socials {
    display: flex;
    flex-direction: column;
  }
  .copyright {
    position: absolute;
    bottom: 1rem;
    right: 1.8rem;
    opacity: 0.2;
  }
}
